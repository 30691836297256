<template>
  <div>
    <v-sheet class="pa-8">
      <v-row justify="center">
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="6"
          xl="6">
          <p class="text-h5">
            สร้างใบสั่งนับ
          </p>
          <FormInput
            :loading="loading"
            :warehouse="warehouse"
            @onSubmit="onSubmit"
            @onCancel="$router.go(-1)"></FormInput>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import StockCountProvider from '@/resources/StockCountProvider'
import { mapGetters } from 'vuex'
import FormInput from '../components/FormInput.vue'

export default {
  components: {
    FormInput
  },
  data () {
    return {
      stockCountProvider: new StockCountProvider(),
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    warehouse () {
      return this.mapWarehouse.filter((wh) => wh.code !== 'main')
    }
  },
  methods: {
    async onSubmit (val) {
      try {
        this.loading = true
        await this.stockCountProvider.createStockCount(val)
        this.$router.push({ name: 'StockCountList' })
      } catch (error) {
        console.error('StockCountList', error)
      }
      this.loading = false
    }
  }
}
</script>
